import React from "react";
import { IFile } from "types";
import FileIcon from "public/icons/file.svg";
import cn from "classnames";
import Icon from "./Icon";
import PaperClipIcon from "public/icons/paperclip-solid.svg";
import { getShortFileName } from "utils/getShortFileName";
import { useBreakpoint } from "contexts/BreakpointContext";

interface IFileViewProps {
  file: IFile | File;
  className?: string;
  textClassName?: string;
  iconClassName?: string;
  nameLimit?: number;
  alt?: boolean;
  onClick?: (file: IFile | File) => void;
}

const FileView: React.FC<IFileViewProps> = ({
  file,
  className,
  textClassName,
  iconClassName,
  nameLimit = 20,
  onClick,
  alt,
}) => {
  const fileName = getShortFileName(file, nameLimit);
  const { xxs } = useBreakpoint();

  if (alt) {
    return (
      <div
        onClick={() => onClick && onClick(file)}
        className={cn(
          "flex items-center",
          onClick && "cursor-pointer",
          className
        )}
      >
        <Icon
          component={PaperClipIcon}
          viewBox="0 0 448 512"
          width={16}
          height={16}
          className="fill-gray-600"
        />
        <p
          className={cn(
            "link-100 ml-2 text-left overflow-hidden text-ellipsis",
            textClassName
          )}
        >
          {fileName}
        </p>
      </div>
    );
  }

  return (
    <div
      onClick={() => onClick && onClick(file)}
      className={cn(
        "flex items-center p-3 bg-white rounded-2xl",
        onClick && "cursor-pointer",
        className
      )}
    >
      <Icon
        component={FileIcon}
        viewBox="0 0 12 12"
        width={xxs ? 20 : 30}
        height={xxs ? 20 : 30}
      />
      <p
        className={cn(
          "body-100 ml-2 text-left overflow-hidden text-ellipsis",
          textClassName
        )}
      >
        {fileName}
      </p>
    </div>
  );
};

export default FileView;
