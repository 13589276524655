import React from "react";
import { InputPropsType } from "components/atoms/Input";
import { useField } from "formik";
import { FormikInputProps } from "./FormikInput";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import omit from "lodash/omit";
import cn from "classnames";

const FormikPriceInput: React.FC<
  FormikInputProps & InputPropsType & CurrencyInputProps
> = ({
  lowerError,
  errorClassName,
  className,
  containerClassName,
  ...props
}) => {
  const [field, meta, tool] = useField(props);

  const onPriceChange = (value?: string) => {
    tool.setValue(value);
  };

  return (
    <div className={cn("relative", containerClassName)}>
      <CurrencyInput
        allowNegativeValue={false}
        placeholder="$0.00"
        decimalsLimit={2}
        decimalScale={2}
        decimalSeparator="."
        groupSeparator=","
        className={cn(
          "px-3 py-[9px] rounded-md border border-border-normal focus:!border-black text-sm",
          className,
          meta.error && meta.touched && "border-border-error"
        )}
        prefix="$"
        onValueChange={onPriceChange}
        {...props}
        {...omit(field, "onChange")}
      />
      {!!meta.error && meta.touched && (
        <div
          className={cn(
            "error-100 absolute -bottom-0.5 whitespace-nowrap",
            lowerError && "-bottom-[17px]",
            errorClassName
          )}
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default FormikPriceInput;
