import React from "react";
import { useField } from "formik";
import Select from "components/atoms/Select";
import { ISelectProps } from "components/atoms/Select";

export interface FormikSelectProps {
  name: string;
  containerClassName?: string;
}

const FormikSelect = ({ ...props }: FormikSelectProps & ISelectProps) => {
  const [field, meta] = useField(props as FormikSelectProps);

  return (
    <div className={props.containerClassName}>
      <Select
        {...field}
        {...props}
        errorMassage={meta.touched && meta.error ? meta.error : ""}
      />
    </div>
  );
};

export default FormikSelect;
