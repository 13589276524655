import Icon from "components/atoms/Icon";
import React from "react";
import { IFile } from "types";
import TrashIcon from "public/icons/bin_16.svg";
import PdfViewer from "../PdfViewer";
import FileView from "components/atoms/FileView";
import { openUrl } from "utils/openUrl";
import ImageResponsive from "components/atoms/ImageResponsive";

interface ISupplierGalleryItem {
  item: IFile;
  onDelete?: (url: string) => void;
  itemWidth?: number;
  itemHeight?: number;
}

const SupplierGalleryItem: React.FC<ISupplierGalleryItem> = ({
  item,
  onDelete,
  itemWidth = 133,
  itemHeight = 122,
}) => {
  const isPdf = item.mimetype === "application/pdf";
  const isImage = item.mimetype.includes("image");

  const onFileClick = (url: string) => {
    openUrl(url);
  };

  return (
    <div
      onClick={() => onFileClick(item.url)}
      className="max-w-[133px] cursor-pointer flex items-center justify-center group relative"
    >
      {isPdf && (
        <PdfViewer
          style={{ width: itemWidth, height: itemHeight }}
          file={item.url}
        />
      )}
      {isImage && (
        <ImageResponsive
          style={{
            width: itemWidth,
            height: itemHeight,
          }}
          url={item.url}
        />
      )}
      {!isImage && !isPdf && <FileView alt file={item} />}
      <div
        className="absolute top-0 left-0 group-hover:block hidden w-full h-full"
        style={{
          background:
            "radial-gradient(62.38% 62.37% at 50% 50%, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.40) 100%)",
        }}
      />
      {!!onDelete && (
        <button
          className="flex p-2 bg-white bg-opacity-70 group-hover:block hidden absolute top-2 right-2 hover:!bg-opacity-100 rounded-md active:bg-opacity-20"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(item.url);
          }}
        >
          <Icon
            component={TrashIcon}
            viewBox="0 0 16 16"
            width={16}
            height={16}
            className="fill-gray-950"
          />
        </button>
      )}
    </div>
  );
};

export default SupplierGalleryItem;
