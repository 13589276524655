"use client";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer: React.FC<{
  file: string;
  style?: any;
  usePlagin?: boolean;
}> = ({ file, style = {}, usePlagin }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
      <div style={{ height: "750px", ...style }}>
        <Viewer
          fileUrl={file}
          plugins={usePlagin ? [defaultLayoutPluginInstance] : undefined}
        />
      </div>
    </Worker>
  );
};

export default PdfViewer;
