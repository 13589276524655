import React, { MouseEventHandler } from "react";
import Icon from "../Icon";
import CheckFillIcon from "public/icons/circle-check-fill.svg";
import useTranslation from "next-translate/useTranslation";
import cn from "classnames";

interface INewOrderFormCardProps {
  completed?: boolean;
  title: string;
  optional?: boolean;
  className?: string;
  rightHeadComponent?: React.ReactNode;
  infoComponent?: React.ReactNode;
  error?: string;
  id?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const NewOrderFormCard: React.FC<INewOrderFormCardProps> = ({
  completed,
  title,
  children,
  optional,
  className,
  rightHeadComponent: RightHeadComponent = null,
  infoComponent: InfoComponent = null,
  error,
  onClick,
  id,
}) => {
  const { t } = useTranslation("common");

  return (
    <div
      id={id}
      onClick={onClick!}
      className={cn(
        "bg-white border border-border-normal rounded-2xl p-6",
        !!onClick && "cursor-pointer",
        className
      )}
    >
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center">
          <h3 className="headline-300 font-semibold">
            {title}
            {optional && (
              <span className="body-100-subtle text-sm font-normal">
                {" - " + t("optional")}
              </span>
            )}
          </h3>
          {InfoComponent}
        </div>
        {RightHeadComponent}
        {completed && !RightHeadComponent && (
          <Icon
            component={CheckFillIcon}
            viewBox="0 0 16 16"
            width={16}
            height={16}
            className="fill-green-70"
          />
        )}
      </div>
      {!!error && <p className="error-100">{error}</p>}
      {children}
    </div>
  );
};

export default NewOrderFormCard;
